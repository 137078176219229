import React from "react";
import Layout from '../components/layout.js';

export default () => {
    return (
        <div id='home'>
        <Layout>
        <h2>Thank you!</h2>
        </Layout>
        </div>
    )
};
